import React from 'react'

const contactPageLink = 'https://policyscout.com/contact'
const privacyOfficer = 'privacyofficer@healthinsurancecompanion.com'
const tollFreeNumber = '1-888-233-2357'

const CCPAPage = () => (
  <div className="container">
    <div className="mv4">
      <center>
        <h2>CCPA</h2>
      </center>
      <p className="p1">
        <span className="s1">This section applies to California residents only.</span>
      </p>
      <p className="p1">
        <span>
          Pursuant to Section 1798.83 of the California Civil Code, residents of
          California have the right to request from a business, with whom the California
          resident has an established business relationship, certain information with
          respect to the types of personal information the business shares with third
          parties for direct marketing purposes by such third party and the identities of
          the third parties with whom the business has shared such information during the
          immediately preceding calendar year. To request such information from Company,
          please contact us by email at{' '}
          <a href={`mailto:${privacyOfficer}`}>
            <span className="s3">{privacyOfficer}</span>
          </a>
          , subject line “Shine the Light”, call toll free {tollFreeNumber} or write to us
          at the address listed in Section 8 below.
        </span>
      </p>
      <p className="p1">
        <span className="s1">
          Pursuant to 1798.100 et seq. of the California Civil Code, residents of
          California may request that a business that collects a California resident’s
          personal information disclose certain categories and specific pieces of personal
          information collected, as permitted under California Consumer Privacy Act. The
          resident may also request that the business delete personal information
          collected about the consumer or request the Company not sell the information. To
          make a request pursuant to the foregoing, you can contact us by email at{' '}
          <a href={`mailto:${privacyOfficer}`}>
            <span className="s3">{privacyOfficer}</span>
          </a>
          , subject line “CCPA”, call toll free {tollFreeNumber} or write to us at the
          address listed in Section 8 below.
        </span>
      </p>
      <p className="p1">
        <span className="s1">
          If you are a California resident and do not want us to sell your Personal
          Information, do not share it with us, or, if you have, click the following DO
          NOT SELL MY PERSONAL INFORMATION, or click the same link which is found on the
          homepage. Further, if you wish to Opt-Out from any of Company’s uses of your
          Personal Information as set forth herein, except in the case of Legal
          Proceedings, please click{' '}
          <a href={contactPageLink} target="__blank">
            here
          </a>
          .
        </span>
      </p>
      <p className="p1">
        <span className="s1">
          In you exercise any of the foregoing rights, Company may require you to provide
          sufficient information to prove your identity and residence prior to Company
          taking action. The information provided shall only be used for this purpose.
        </span>
      </p>
    </div>
  </div>
)

export default CCPAPage
